exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-histoire-js": () => import("./../../../src/pages/en/histoire.js" /* webpackChunkName: "component---src-pages-en-histoire-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-les-vins-js": () => import("./../../../src/pages/en/les-vins.js" /* webpackChunkName: "component---src-pages-en-les-vins-js" */),
  "component---src-pages-en-location-js": () => import("./../../../src/pages/en/location.js" /* webpackChunkName: "component---src-pages-en-location-js" */),
  "component---src-pages-en-notre-terroire-js": () => import("./../../../src/pages/en/notre-terroire.js" /* webpackChunkName: "component---src-pages-en-notre-terroire-js" */),
  "component---src-pages-en-nous-contacter-js": () => import("./../../../src/pages/en/nous-contacter.js" /* webpackChunkName: "component---src-pages-en-nous-contacter-js" */),
  "component---src-pages-histoire-js": () => import("./../../../src/pages/histoire.js" /* webpackChunkName: "component---src-pages-histoire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-vins-js": () => import("./../../../src/pages/les-vins.js" /* webpackChunkName: "component---src-pages-les-vins-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-notre-terroire-js": () => import("./../../../src/pages/notre-terroire.js" /* webpackChunkName: "component---src-pages-notre-terroire-js" */),
  "component---src-pages-nous-contacter-js": () => import("./../../../src/pages/nous-contacter.js" /* webpackChunkName: "component---src-pages-nous-contacter-js" */),
  "component---src-templates-wine-template-js": () => import("./../../../src/templates/WineTemplate.js" /* webpackChunkName: "component---src-templates-wine-template-js" */)
}

